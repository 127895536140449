import React, {useEffect} from 'react';
import {navigate} from 'gatsby';

const Pricing = () => {
  useEffect(() => {
    navigate('/pricing/agency');
  }, []);

  return <></>;
};

export default Pricing;
